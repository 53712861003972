<template>
  <div>

    <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
      <!--sidebar-->
      <vs-sidebar class="items-no-padding" parent="#todo-app" :click-not-close="sidebar.clickNotClose" :hidden-background="sidebar.clickNotClose" v-model="sidebar.active">
        <component :is="scrollbarTag" class="todo-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.60 }">
          <div class="px-6">
            <div class="vx-card vx-col w-full mb-5 shadow-none">
              <h5 class="mb-5">Pilih Jabatan Dealer</h5>
            </div>
            <div v-if="sidebar.loading" class="animate-pulse">
              <h6>Loading...</h6>
              <vs-progress indeterminate color="primary" :height="1"/>
            </div>
            <template v-if="!sidebar.loading">
              <p v-if="isSidebarEmpty" class="text-sm mt-4">Tidak ada data.</p>
              <span class="flex mt-6 cursor-pointer" v-for="(item, index) in sidebar.items" :key="index" :class="{'text-primary font-semibold': item === sidebar.selected}" @click="onSidebarSelected(item)">
                <feather-icon icon="ChevronRightIcon" :svgClasses="[{'text-primary stroke-current': item === sidebar.selected}, 'heads-6 w-6']"></feather-icon>
                <span class="text-lg ml-3">{{ item.name }}</span>
              </span>
            </template>
          </div>
        </component>
      </vs-sidebar>

      <!--box-->
      <div :class="{'sidebar-spacer': sidebar.clickNotClose}" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content">
        <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">
          <!--toggle sidebar-->
          <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleSidebar(true)"/>

          <!--search -->
          <div class="w-full flex">
            <vs-input icon-no-border size="large" icon-pack="feather" icon="icon-search" placeholder="Cari" v-model="search" class="vs-input-no-border vs-input-no-shdow-focus w-7/12 sm:w-10/12"/>
            <vs-button @click="save" color="primary" type="filled" class="w-5/12 sm:w-2/12 rounded-none" :disabled="!box.unsaved || box.saving">
              <span v-if="box.saving" class="animate-pulse">Menyimpan...</span>
              <span v-if="!box.saving">Simpan</span>
            </vs-button>
          </div>
        </div>

        <!--main content -->
        <component :is="scrollbarTag" class="todo-content-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.60 }">
          <div class="w-full" :class="{'px-2 pt-4': box.errors !== null}"><ValidationErrors :errors="box.errors"/></div>
          <h6 v-if="box.loading"  class="p-10 text-center animate-pulse">Loading...</h6>
          <p v-if="isBoxEmpty" class="p-10 text-center">Tidak ada data.</p>

          <div class="w-full p-2" v-if="!box.loading && sidebar.selected.id != null">
            <h5 class="mb-3">Penerima Komisi</h5>
            <!--------CHECKED-------->
            <div>
              <vx-card v-for="item in boxItemsChecked" :key="item.id" class="rounded-none mb-2 border border-primary border-solid">
                <div class="vx-row">
                  <div class="vx-col w-full flex sm:items-center sm:flex-row flex-col">
                    <div class="flex items-start">
                      <vs-checkbox class="w-8 m-0 mt-0.75 vs-checkbox-small" :checked="true" @change="onUnchecked(item.id)"/>
                      <h5 class="todo-title">{{ item.nama_roles_receiver }}
                        <span class="text-primary">({{ item.kode_roles_receiver }})</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </vx-card>
            </div>
            <!--------UNCHECKED-------->
            <div>
              <div v-for="item in boxItemsUnchecked" :key="item.id" class="p-6 vx-card rounded-none mb-2 bg-opacity-25">
                <div class="vx-row">
                  <div class="vx-col w-5/6 flex sm:items-center sm:flex-row flex-col">
                    <div class="flex items-start">
                      <vs-checkbox class="w-8 m-0 mt-0.75 vs-checkbox-small" :checked="false" @change="onChecked(item.id)"/>
                      <h5 class="todo-title">{{ item.name }}
                        <span class="text-primary">({{ item.kode }})</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </component>

      </div>
    </div>

  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Draggable from 'vuedraggable'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import RolesRepository from '@/repositories/master/roles-repository'
import DealerRepository from '@/repositories/marketing/dealer-repository'
import vSelect from 'vue-select'

export default {
  name: 'SetDealer',
  components: {
    ValidationErrors,
    VuePerfectScrollbar,
    Draggable,
    'v-select': vSelect
  },
  mounted () {
    this.getDataSidebar()
    this.getDataBox()
  },
  created () {
    this.setSidebarWidth()
  },
  data () {
    return {
      search: '',
      sidebar: {
        clickNotClose: true,
        active: true,
        loading: false,
        items: [],
        selected: {}
      },
      box: {
        errors: null,
        loading: false,
        saving: false,
        unsaved: false,
        items: [],
        checked: []
      }
    }
  },
  watch: {
    'sidebar.selected' () {
      this.getDataBoxChecked()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    boxItemsChecked () {
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        return _.filter(this.box.checked, (item) => {
          return item.nama_roles_receiver.toLowerCase().includes(search) || item.kode_roles_receiver.toLowerCase().includes(search)
        })
      }
      return this.box.checked
    },
    boxItemsUnchecked () {
      const uncheckedItems = _.reject(this.box.items, (item) => _.find(this.box.checked, { id_roles_receiver: item.id }))
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        return _.filter(uncheckedItems, (item) => {
          return item.name.toLowerCase().includes(search) || item.kode.toLowerCase().includes(search)
        })
      }
      return uncheckedItems
    },
    isBoxEmpty () {
      return (!this.box.loading && this.box.items.length < 1 && this.box.checked.length < 1) || this.sidebar.selected.id == null
    },
    isSidebarEmpty () {
      return this.sidebar.items.length < 1 && !this.sidebar.loading
    },
    isUnsavedChanges () {
      return this.box.unsaved
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    windowWidth () {
      return this.$store.state.theme.windowWidth
    }
  },
  methods: {
    onChecked (id) {
      const boxItem = _.find(this.box.items, { id: id })
      const sidebarItem = this.sidebar.selected
      const newItemChecked = {
        id: uuid(),
        id_roles_dealer: sidebarItem.id,
        id_roles_receiver: boxItem.id,
        nama_roles_dealer: sidebarItem.name,
        kode_roles_dealer: sidebarItem.kode,
        nama_roles_receiver: boxItem.name,
        kode_roles_receiver: boxItem.kode
      }

      setTimeout(() => {
        this.box.checked.push(newItemChecked)
        this.box.unsaved = true
        this.search = ''
      }, 250)
    },

    onUnchecked (id) {
      setTimeout(() => {
        const index = _.findIndex(this.box.checked, item => item.id === id)
        this.box.checked.splice(index, 1)
        this.box.unsaved = true
        this.search = ''
      }, 250)
    },

    getDataSidebar () {
      this.sidebar.loading = true

      RolesRepository.getAll()
        .then(response => {
          this.sidebar.items = response.data.data
          if (this.sidebar.items.length > 0) {
            this.sidebar.selected = this.sidebar.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.sidebar.loading = false
        })
    },

    getDataBox () {
      RolesRepository.getAll()
        .then(response => {
          this.box.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    getDataBoxChecked () {
      this.box.loading = true
      this.box.checked = []
      const params = {
        id_roles_dealer: this.sidebar.selected.id
      }

      DealerRepository.all(params)
        .then(response => {
          this.box.checked = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.box.loading = false
        })
    },

    save () {
      this.box.errors = null
      this.box.saving = true
      const params = {
        id_roles_dealer: this.sidebar.selected.id,
        items: this.box.checked
      }

      DealerRepository.save(params)
        .then(response => {
          this.box.unsaved = false
          this.getDataBoxChecked()
          this.notifySuccess('Data berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.box.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.box.saving = false
        })
    },

    onSidebarSelected (item) {
      // confirm unsaved
      if (this.isUnsavedChanges) {
        this.$vs.dialog({
          ...this.confirmLeaveOptions(),
          accept: () => {
            this.box.unsaved = false
            this.onSidebarSelected(item)
          }
        })
        return
      }

      this.sidebar.selected = item
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.sidebar.active = this.sidebar.clickNotClose = false
      } else {
        this.sidebar.active = this.sidebar.clickNotClose = true
      }
    },

    toggleSidebar (value = false) {
      if (!value && this.sidebar.clickNotClose) return
      this.sidebar.active = value
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.box.unsaved) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>

<style lang="scss">
  @import "~@/assets/scss/vuexy/apps/todo.scss";
</style>
