import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/dealer'

export default {
  save (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  all (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/all${queryParams}`)
  }
}
